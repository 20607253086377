import * as React from "react";
import { useEffect } from "react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import Script from "next/script";
import Router, { useRouter } from "next/router";
import { SessionProvider } from "next-auth/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import type { AppProps } from "next/app";
import Head from "next/head";
import theme from "../src/theme";
import { APP_NAME } from "constants/global";
import { useSession } from "next-auth/react";
import { QueryClientProvider, QueryClient } from "react-query";
import { trackEvent as trackClientSide } from "analytics/tracking-cs";
// import { ReactQueryDevtools } from 'react-query/devtools'

import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from "recoil";



// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }



// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }


const FEATURES_ENDPOINT = "https://cdn.growthbook.io/api/features/prod_xXyEZoRmP1rleAYEYKdwhlFTelnUg3l6jtBQpmZSs0";
const growthbook = new GrowthBook({
    trackingCallback: (experiment, result) => {
      console.log("Viewed Experiment", experiment, result);
    },
  });

type PageProps = {
    [key: string]: any;
  };

export default function Metric(props: AppProps) {
    const { Component } = props;
    const pageProps:PageProps = props.pageProps;
    
    const [isClient, setIsClient] = React.useState(false)
 
    useEffect(() => {
      setIsClient(true)
    }, [])

    const queryClient = new QueryClient();
    const userId =
        pageProps.session &&
        pageProps.session !== undefined &&
        pageProps.session?.account &&
        Array.isArray(pageProps.session?.account)
            ? pageProps.session?.account[0]?._id
            : null;

    const userEmail = 
        pageProps.session &&
        pageProps.session !== undefined &&
        pageProps.session?.account &&
        Array.isArray(pageProps.session?.account)
            ? pageProps.session?.account[0]?.account
            : userId;

    const router = useRouter();
    useEffect(() => {
        fetch(FEATURES_ENDPOINT)
        .then((res) => res.json())
        .then((json) => {
            growthbook.setFeatures(json.features);
        });

        growthbook.setAttributes({
            id: userEmail,
            loggedIn: true,
            // deviceId: null,
            // employee: null,
            // company: ,
            // country: "US",
            browser: navigator.userAgent,
            url: router.pathname,
        });

        const handleRouteChange = (url: any, { shallow }: any) => {
            trackClientSide(userId, "PAGE", "VIEW", router.pathname, router);
        };

        router.events.on("routeChangeStart", handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
        };
    }, [router.pathname, userId]);

    return (
        <SessionProvider session={pageProps.session}>
            <QueryClientProvider client={queryClient} contextSharing={true}>
                <Head>
                    <title>{APP_NAME}</title>
                    <meta
                        content="minimum-scale=1, initial-scale=1, width=device-width"
                        name="viewport"
                    />
                    <meta name="theme-color" content="#ffffff" />
                </Head>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <RecoilRoot>
                            {isClient &&
                            <GrowthBookProvider growthbook={growthbook}>
                                <Component {...pageProps} />
                            </GrowthBookProvider>
                            }
                        </RecoilRoot>
                    </ThemeProvider>
                </StyledEngineProvider>
                {/* <ReactQueryDevtools /> */}
            </QueryClientProvider>
        </SessionProvider>
    );
}
