import mixpanel from "mixpanel-browser";
import { eventDomain, eventAction, ValueOf, Event } from "constants/global";

// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production

mixpanel.init(process.env.NEXT_PUBLIC_MIX_PANEL_TOKEN ?? "", { debug: true });
// mixpanel.track("INIT", {
//     source: "localhost",
//     "Did call INIT": true,
// });

export const trackEvent = (
    distinct_id: string,
    domain: ValueOf<typeof eventDomain>,
    action: ValueOf<typeof eventAction>,
    name: string,
    data?: Object | null
): void => {
    // console.log('track event:')
    // console.log(name)
    mixpanel.track(name, {
        distinct_id: distinct_id,
        domain: domain,
        action: action,
        data: data ?? null,
    });
};
