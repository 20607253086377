import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

declare module "@mui/material/styles" {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: "#354c83",
            light: "#5867dd",
            dark: "#51b4e1",
        },
        secondary: {
            main: "#00c5da",
            light: "#64f8ff",
            dark: "#0094a8",
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontFamily: '"Montserrat", sans-serif',
        subtitle1: {
            fontFamily: '"Montserrat", sans-serif',
            marginTop: "2em",
        },
        h6: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "24pt",
        },
        h4: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1rem",
            padding: "1em",
            marginBottom: "1em",
            letterSpacing: 3,
            position: "sticky",
            // top: "62px",
            // left: "-30px",
            width: "100%",
            color: "#354d84",
            backgroundColor: "#ffffff",
            // borderBottom: "1px solid #354d84",
            // zIndex: 999,
        },
        subtitle2: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1rem",
            padding: "1em",
            marginBottom: "1em",
            letterSpacing: 3,
            position: "sticky",
            top: "62px",
            left: "-30px",
            width: "100%",
            color: "#354d84",
            backgroundColor: "#ffffff",
            borderBottom: "1px solid #354d84",
            // boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
            zIndex: 999,
        },
    },
});

export default theme;
