import { HttpResponse } from "aws-sdk";
import { deepFreeze } from "grommet/utils";

export const APP_NAME = "Metric Portal";

export type ValueOf<T> = T[keyof T];

export const customTheme = deepFreeze({
    global: {
        colors: {
            brand: "#354c83",
            interaction: "silver",
            remove: "red",
            focus: "rgb(0, 101, 107)",
            selected: "#354c83",
            "status-ok": "#00873D",
        },
        breakpoints: {
            xsmall: {
                value: 375,
            },
            small: {
                value: 750,
            },
            medium: {
                value: 900,
            },
            large: {
                value: 3000,
            },
        },
    },
});

export const eventDomain = {
    AUTH: "AUTH",
    OVERVIEW: "OVERVIEW",
    PERFORMANCE: "PERFORMANCE",
    PROFILE: "PROFILE",
    CONTACT: "CONTACT",
    TASK: "TASK",
    GRAPHQL: "GRAPHQL",
};

export const eventAction = {
    USER: "USER",
    VIEW: "VIEW",
    ADD: "ADD",
    EDIT: "EDIT",
    DELETE: "DELETE",
    ADD_EDIT: "ADD_EDIT",
    SAVE: "SAVE",
    CANCEL: "CANCEL",
    RESET: "RESET",
    CLOSE: "CLOSE",
    SYSTEM: "SYSTEM",
    ERROR: "ERROR",
    SEARCH: "SEARCH",
    UPLOAD: "UPLOAD",
    SHEET_SELECT: "SHEET_SELECT",
    COLUMNS_SELECTED: "COLUMNS_SELECTED",
    STEP_1_COMPLETE: "STEP_1_COMPLETE",
    STEP_2_COMPLETE: "STEP_2_COMPLETE",
    STEP_3_COMPLETE: "STEP_3_COMPLETE",
};

// NOT USED
export type EventAction = {
    param1: keyof typeof eventAction;
};
export interface Event {
    (distinct_id: String, domain: keyof typeof eventDomain, action: keyof typeof eventAction, name: String, data?: Object | null): void;
}

export interface IStepperSections {
    dynamicFormVals: any;
    saveIdx: Number;
    getDefaultValue: Function;
    removeFormFields: Function;
    fieldChangeDF: Function;
    addFormFields: Function;
    allowAdd?: boolean;
}

export const checkResponseStatus = (res: any) => {
    if (res.ok) {
        return res;
    } else {
        throw new Error(`The HTTP status of the reponse: ${res.status} (${res.statusText})`);
    }
};

export enum States {
    AL = "Alabama",
    AK = "Alaska",
    AS = "American Samoa",
    AZ = "Arizona",
    AR = "Arkansas",
    CA = "California",
    CO = "Colorado",
    CT = "Connecticut",
    DE = "Delaware",
    DC = "District Of Columbia",
    FM = "Federated States Of Micronesia",
    FL = "Florida",
    GA = "Georgia",
    GU = "Guam",
    HI = "Hawaii",
    ID = "Idaho",
    IL = "Illinois",
    IN = "Indiana",
    IA = "Iowa",
    KS = "Kansas",
    KY = "Kentucky",
    LA = "Louisiana",
    ME = "Maine",
    MH = "Marshall Islands",
    MD = "Maryland",
    MA = "Massachusetts",
    MI = "Michigan",
    MN = "Minnesota",
    MS = "Mississippi",
    MO = "Missouri",
    MT = "Montana",
    NE = "Nebraska",
    NV = "Nevada",
    NH = "New Hampshire",
    NJ = "New Jersey",
    NM = "New Mexico",
    NY = "New York",
    NC = "North Carolina",
    ND = "North Dakota",
    MP = "Northern Mariana Islands",
    OH = "Ohio",
    OK = "Oklahoma",
    OR = "Oregon",
    PW = "Palau",
    PA = "Pennsylvania",
    PR = "Puerto Rico",
    RI = "Rhode Island",
    SC = "South Carolina",
    SD = "South Dakota",
    TN = "Tennessee",
    TX = "Texas",
    UT = "Utah",
    VT = "Vermont",
    VI = "Virgin Islands",
    VA = "Virginia",
    WA = "Washington",
    WV = "West Virginia",
    WI = "Wisconsin",
    WY = "Wyomig",
}

export enum MaleSuffixs {
    NA = "",
    SR = "Sr",
    JR = "Jr",
    II = "II",
    III = "III",
    IV = "IV",
    V = "V",
}

export enum Gender {
    male = "Male",
    female = "Female",
    non_binary = "Non-binary",
    other = "Other",
}

export enum UiGapSizes {
    small = "small",
    medium = "medium",
    large = "large",
}

export enum AccumulatorTypes {
    SUM = "SUM",
    COUNT = "COUNT",
    AVG = "AVG",
}

export const COLORS = [
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#66aa00",
    "#b82e2e",
    "#316395",
    "#3366cc",
    "#994499",
    "#22aa99",
    "#aaaa11",
    "#6633cc",
    "#e67300",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#743411",
];
export const patternColorMap = new Map()
    .set("Chameleon", COLORS[1])
    .set("Trailblazer", COLORS[2])
    .set("Facilitator", COLORS[3])
    .set("Enterpriser", COLORS[4])
    .set("Traditionalist", COLORS[5])
    .set("Rainmaker", COLORS[6])
    .set("Debater", COLORS[7])
    .set("Scholar", COLORS[8])
    .set("Administrator", COLORS[9])
    .set("Architect", COLORS[10])
    .set("Coordinator", COLORS[11])
    .set("Avoidant", COLORS[12])
    .set("Influencer", COLORS[13])
    .set("Operator", COLORS[14])
    .set("Specialist", COLORS[15])
    .set("Persuader", COLORS[16])
    .set("Philosopher", COLORS[17])
    .set("Technical Expert", COLORS[18])
    .set("Socializer", COLORS[19])
    .set("Craftsman", COLORS[20])
    .set("No Email", COLORS[21])
    .set("No Survey", COLORS[23]); // #22 if green, so don't use green
